// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "q_rc d_bz";
export var storyRowWrapper = "q_hv d_hv d_bG";
export var storyLeftWrapper = "q_rd d_bw d_bL";
export var storyWrapperFull = "q_rf d_cz";
export var storyWrapperFullLeft = "q_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "q_mC d_hw";
export var storyLeftWrapperCenter = "q_rg d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "q_rh d_hC";
export var storyHeader = "q_rj d_hB d_s d_cp";
export var storyHeaderCenter = "q_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "q_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "q_rk d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "q_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "q_qw d_fc d_W";
export var imageWrapperFull = "q_qx d_s d_D d_bb d_W";