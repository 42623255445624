// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qJ d_fl d_bC d_dr";
export var alignLeft = "p_qd d_fl d_bC d_dr";
export var alignDiscCenter = "p_qK d_fm d_bz d_ds";
export var alignCenter = "p_bL d_fm d_bz d_ds";
export var alignDiscRight = "p_qL d_fn d_bD d_dt";
export var alignRight = "p_qf d_fn d_bD d_dt";
export var footerContainer = "p_qM d_dS d_bS";
export var footerContainerFull = "p_qN d_dQ d_bS";
export var footerHeader = "p_kc d_kc";
export var footerTextWrapper = "p_qP d_s";
export var footerDisclaimerWrapper = "p_kk d_kk d_cg";
export var badgeWrapper = "p_qQ d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "p_kl d_kl d_bw";
export var footerDisclaimerLeft = "p_km d_km d_bw";
export var verticalTop = "p_qR d_bw d_bF d_bK d_bH";
export var firstWide = "p_qS";
export var disclaimer = "p_qT";
export var socialDisclaimer = "p_qV";
export var left = "p_qW";
export var wide = "p_qX d_cv";
export var right = "p_qY d_bG";
export var line = "p_fg d_fh d_cr";
export var badgeDisclaimer = "p_qZ d_by d_bL d_bF";
export var badgeContainer = "p_q0 d_bw d_bD d_bL";
export var badge = "p_q1";
export var padding = "p_q2 d_c4";
export var end = "p_q3 d_bD";
export var linkWrapper = "p_q4 d_dx";
export var link = "p_mF d_dx";
export var colWrapper = "p_q5 d_ct";
export var media = "p_q6 d_bt d_dv";
export var itemRight = "p_q7";
export var itemLeft = "p_q8";
export var itemCenter = "p_q9";
export var exceptionWeight = "p_rb r_rN";