// extracted by mini-css-extract-plugin
export var lbContainerOuter = "t_sR";
export var lbContainerInner = "t_sS";
export var movingForwards = "t_sT";
export var movingForwardsOther = "t_sV";
export var movingBackwards = "t_sW";
export var movingBackwardsOther = "t_sX";
export var lbImage = "t_sY";
export var lbOtherImage = "t_sZ";
export var prevButton = "t_s0";
export var nextButton = "t_s1";
export var closing = "t_s2";
export var appear = "t_s3";